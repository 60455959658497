import useStore from '../Store/gamma_expected_move_data_store';

export const gexIndicator = function (PineJS) {
  return Promise.resolve([
    {
      name: 'Raw GEX',
      metainfo: {
        metainfoVersion: 53,
        id: "Raw*GEX@tv-basicstudies-1",
        description: "Raw GEX",
        shortDescription: "Raw GEX ($ Billion)",
        format: { type: "inherit"
        },
        linkedToSeries: false,
        is_price_study: false,
        plots: [
          { id: "gex_plot", type: "line" }
        ],
        defaults: {
          styles: {
            gex_plot: {
              linestyle: 0,
              linewidth: 1,
              plottype: 5,
              trackPrice: false,
              transparency: 60,
              visible: true,
              color: "rgba(177,136,0,0.5)",
            }
          },
        },
        styles: {
          gex_plot: { title: "Raw GEX (Billions)", histogramBase: 0, joinPoints: true }
        },
        inputs: [],
      },
      constructor: function () {
        this.init = function (ctx) {
          this._context = ctx;

          // console.log('Initializing GEX Indicator');
          const spxGexData = useStore.getState().spxGexData || [];
          // console.log('Raw GEX Data first entry:', spxGexData[0]);

          if (!spxGexData || spxGexData.length === 0) {
            // console.log('No GEX data available');
            this._gexData = [];
            return;
          }

          const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

          this._gexData = spxGexData
            .map(entry => {
              const rawValue = entry.gex === null ? 0 : parseFloat(entry.gex);
              // Round to one decimal place and convert to billions
              const value = Math.round(rawValue / 1e8) / 10;

              const timestamp = new Date(entry.date).getTime();
              // console.log('Processing entry:', {
              //   date: entry.date,
              //   timestamp: timestamp,
              //   adjustedTimestamp: timestamp + ONE_DAY_IN_MS,
              //   rawValue: rawValue,
              //   value: value
              // });

              return {
                time: Math.floor((timestamp + ONE_DAY_IN_MS) / 1000), // Push forward by one day
                value: value,
              };
            });

          // console.log('Processed first GEX entry:', this._gexData[0]);
        };

        this.main = function (ctx) {
          const currentTime = Math.floor(ctx.symbol.time / 1000);

          if (!this._gexData || this._gexData.length === 0) {
            return [NaN];
          }

          let closestMatch = null;
          let smallestDiff = Infinity;
          for (const data of this._gexData) {
            const timeDiff = Math.abs(data.time - currentTime);
            if (timeDiff < smallestDiff) {
              closestMatch = data;
              smallestDiff = timeDiff;
            }
          }

          if (closestMatch) {
            return [closestMatch.value];
          }

          return [NaN];
        };
      },
    },
  ]);
};