const { create } = require('zustand');

const useStore = create((set) => ({
  spxData: [],
  spxGexData: [],
  vvixData: [],
  expectedMoveData: [],
  pairedData: [],

  upperBoundPercent: null,
  lowerBoundPercent: null,
  spxUpperBound: null,
  spxLowerBound: null,

  // GEX statistics stores remain the same...

  // Setter for SPX data
  setSpxData: (data) => {
    // console.log('Setting SPX Data:', data);
    set({ spxData: data });
  },

  // Setter for SPX GEX data
  setSpxGexData: (data) => {
    // console.log('Setting SPX GEX Data:', {
    //   length: data.length,
    //   firstEntry: data[0],
    //   lastEntry: data[data.length - 1]
    // });
    set({ spxGexData: data });
  },

  // Setter for VVIX data
  setVvixData: (data) => {
    // console.log('Setting VVIX Data:', {
    //   length: data.length,
    //   firstEntry: data[0],
    //   lastEntry: data[data.length - 1]
    // });
    set({ vvixData: data });
  },

  // Setter for paired data
  setPairedData: (data) => {
    // console.log('Setting Paired Data:', {
    //   length: data.length,
    //   firstEntry: data[0],
    //   lastEntry: data[data.length - 1]
    // });
    set({ pairedData: data });
  },

  // Setter for calculated expected move data
  setExpectedMoveData: (data) => {
    // console.log('Setting Expected Move Data:', {
    //   length: data.length,
    //   firstEntry: data[0],
    //   lastEntry: data[data.length - 1]
    // });
    set({ expectedMoveData: data });
  },

  // Setter for GEX statistics VVIX Below
  setGexStatisticsByDay_vvixBelow: (day, statistics) => {
    // console.log(`Setting GEX Statistics Day ${day} VVIX Below:`, statistics);
    set((state) => ({
      ...state,
      [`gexStatisticsDay${day}_vvixBelow`]: statistics,
    }));
  },

  // Setter for GEX statistics VVIX Above
  setGexStatisticsByDay_vvixAbove: (day, statistics) => {
    // console.log(`Setting GEX Statistics Day ${day} VVIX Above:`, statistics);
    set((state) => ({
      ...state,
      [`gexStatisticsDay${day}_vvixAbove`]: statistics,
    }));
  },

  // Setter for expected move data by day
  setExpectedMoveDataByDay: (dayRange, data) => {
    // console.log(`Setting Expected Move Data for Day ${dayRange}:`, {
    //   length: data.length,
    //   firstEntry: data[0],
    //   lastEntry: data[data.length - 1]
    // });
    set((state) => ({
      expectedMoveData: state.expectedMoveData.map((entry, index) => {
        const dayKey = `day${dayRange}Diff`;
        return {
          ...entry,
          [dayKey]: data[index] ? data[index][dayKey] : null,
        };
      }),
    }));
  },

  // Setter for bounds data
  setBoundsData: (upperPercent, lowerPercent, spxUpper, spxLower) => {
    console.log('Setting Bounds Data:', {
      upperPercent,
      lowerPercent,
      spxUpper,
      spxLower
    });
    set({
      upperBoundPercent: upperPercent,
      lowerBoundPercent: lowerPercent,
      spxUpperBound: spxUpper,
      spxLowerBound: spxLower,
    });
  },

  // Update market data
  updateMarketData: (spxData, spxGexData, vvixData) => {
    // console.log('Updating Market Data:', {
    //   spxDataLength: spxData?.length || 0,
    //   spxGexDataLength: spxGexData?.length || 0,
    //   vvixDataLength: vvixData?.length || 0,
    //   spxGexFirstEntry: spxGexData?.[0],
    //   spxGexLastEntry: spxGexData?.[spxGexData?.length - 1]
    // });
    set({
      spxData: spxData || [],
      spxGexData: spxGexData || [],
      vvixData: vvixData || [],
    });
  },
}));

// Add a subscriber to log state changes
useStore.subscribe(
  (state) => state.spxGexData,
  // (spxGexData) => {
  //   console.log('spxGexData changed:', {
  //     length: spxGexData.length,
  //     firstEntry: spxGexData[0],
  //     lastEntry: spxGexData[spxGexData.length - 1]
  //   });
  // }
);

module.exports = useStore;














