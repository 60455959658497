import useStore from '../Store/gamma_expected_move_data_store';

class CustomDataFeed {
  // Fetch configuration for the TradingView widget
  onReady(callback) {
    const configuration = {
      supported_resolutions: ['1D'],
      symbols_types: [{ name: 'SPX', value: 'SPX' }], // Only SPX as a symbol
    };
    setTimeout(() => callback(configuration), 0);
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    // Define only the SPX symbol, since GEX will be a custom indicator
    const symbolInfoMap = {
      SPX: {
        name: 'SPX',
        ticker: 'SPX',
        type: 'index',
        session: '0900-1600',
        timezone: 'America/New_York',
        minmov: 1,
        pricescale: 100,
        has_intraday: false,
        has_daily: true,
        supported_resolutions: ['1D'],
        exchange: 'NYSE',
      },
    };

    const symbolInfo = symbolInfoMap[symbolName];
    if (symbolInfo) {
      setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
    } else {
      console.error(`Symbol "${symbolName}" not found.`);
      onResolveErrorCallback && onResolveErrorCallback('Invalid symbol');
    }
  }

  getBars(symbolInfo, resolution, { from, to }, onHistoryCallback, onErrorCallback) {
    try {
      const { spxData } = useStore.getState(); // Fetch only SPX data from the store

      let data = [];
      if (symbolInfo.ticker === 'SPX') {
        data = spxData;
      } else {
        console.error(`Unsupported symbol: ${symbolInfo.ticker}`);
        onErrorCallback('Unsupported symbol');
        return;
      }

      const fixedFrom = new Date('2012-01-03T00:00:00Z').getTime() / 1000;

      const filteredData = data
        .filter((entry) => {
          const entryTime = new Date(entry.date).getTime() / 1000;
          return entryTime >= fixedFrom && entryTime <= to; // Use the fixed "from" date
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date)); // Ensure data is sorted by date

      const bars = filteredData.map((entry) => ({
        time: new Date(entry.date).getTime(),
        open: parseFloat(entry.open),
        high: parseFloat(entry.high),
        low: parseFloat(entry.low),
        close: parseFloat(entry.close),
      }));

      if (bars.length > 0) {
        console.log(`Fetched ${bars.length} bars for ${symbolInfo.ticker}.`);
        onHistoryCallback(bars, { noData: false });
      } else {
        console.warn(
          `No data available for ${symbolInfo.ticker} in the requested range (${new Date(from * 1000).toISOString()} to ${new Date(to * 1000).toISOString()}).`
        );
        onHistoryCallback([], { noData: true });
      }
    } catch (error) {
      console.error(`Error fetching bars for ${symbolInfo.ticker}:`, error);
      onErrorCallback(error.message);
    }
  }

  // No changes to subscription methods for now
  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    console.log(`Subscribed to real-time updates for ${symbolInfo.ticker} (UID: ${subscriberUID})`);
  }

  unsubscribeBars(subscriberUID) {
    console.log(`Unsubscribed from real-time updates (UID: ${subscriberUID})`);
  }
}

export default CustomDataFeed;