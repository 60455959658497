const useStore = require('../Store/gamma_expected_move_data_store');

// Helper function to calculate mean
const calculateMean = (values) => {
  const sum = values.reduce((acc, value) => acc + value, 0);
  return sum / values.length;
};

// Helper function to calculate standard deviation
const calculateStandardDeviation = (values, mean) => {
  const squaredDiffs = values.map(value => Math.pow(value - mean, 2));
  const avgSquareDiff = calculateMean(squaredDiffs);
  return Math.sqrt(avgSquareDiff);
};

// Helper function to calculate skewness
const calculateSkewness = (data, mean, stdDev) => {
  const n = data.length;
  const cubedDiffs = data.map(value => Math.pow(value - mean, 3));
  const sumCubedDiffs = cubedDiffs.reduce((acc, value) => acc + value, 0);
  const skewness = (n / ((n - 1) * (n - 2))) * (sumCubedDiffs / Math.pow(stdDev, 3));
  return skewness;
};

const calculateKurtosis = (data, mean, stdDev) => {
  const n = data.length;
  const fourthPowerDiffs = data.map(value => Math.pow(value - mean, 4));
  const sumFourthPowerDiffs = fourthPowerDiffs.reduce((acc, value) => acc + value, 0);
  const kurtosis = (n * (n + 1)) / ((n - 1) * (n - 2) * (n - 3)) * (sumFourthPowerDiffs / Math.pow(stdDev, 4)) -
    (3 * Math.pow(n - 1, 2)) / ((n - 2) * (n - 3));
  return kurtosis;
};

const adjustBoundsForSkewnessKurtosis = (mean, stdDev, skewness, kurtosis) => {
  const skewnessFactor = 1 + Math.abs(skewness) * 0.01;  // Adjust skewness sensitivity
  const kurtosisFactor = 1 + Math.abs(kurtosis) * 0.03;  // Adjust kurtosis sensitivity

  let upperBound = mean + stdDev;
  let lowerBound = mean - stdDev;

  if (skewness > 0) {
    upperBound = mean + skewnessFactor * stdDev;
  } else if (skewness < 0) {
    lowerBound = mean - skewnessFactor * stdDev;
  }

  if (kurtosis > 0) {
    upperBound *= kurtosisFactor;
    lowerBound *= kurtosisFactor;
  } else if (kurtosis < 0) {
    upperBound /= kurtosisFactor;
    lowerBound /= kurtosisFactor;
  }

  return { upperBound, lowerBound };
};

// Function to calculate GEX statistics for a given day range (day 1 to day 5)
const calculateGexStatisticsForDays = (pairedData, dayRange) => {
  const intervals = {
    'Negative (< -2B)': [],
    '-2B to -1B': [],
    '-1B to 0B': [],
    '0B to 1B': [],
    '1B to 2B': [],
    '2B to 3B': [],
    '3B to 4B': [],
    '4B to 5B': [],
    '5B to 6B': [],
    '6B to 7B': [],
    '7B+': []
  };

  pairedData.forEach(({ gexValue, day1Diff, day2Diff, day3Diff, day4Diff, day5Diff, price }) => {
    const priceDifferencePercentByDay = [day1Diff, day2Diff, day3Diff, day4Diff, day5Diff];
    const priceDiff = priceDifferencePercentByDay[dayRange - 1];

    if (gexValue < -2e9) {
      intervals['Negative (< -2B)'].push({ priceDiff, price });
    } else if (gexValue >= -2e9 && gexValue < -1e9) {
      intervals['-2B to -1B'].push({ priceDiff, price });
    } else if (gexValue >= -1e9 && gexValue < 0) {
      intervals['-1B to 0B'].push({ priceDiff, price });
    } else if (gexValue >= 0 && gexValue < 1e9) {
      intervals['0B to 1B'].push({ priceDiff, price });
    } else if (gexValue >= 1e9 && gexValue < 2e9) {
      intervals['1B to 2B'].push({ priceDiff, price });
    } else if (gexValue >= 2e9 && gexValue < 3e9) {
      intervals['2B to 3B'].push({ priceDiff, price });
    } else if (gexValue >= 3e9 && gexValue < 4e9) {
      intervals['3B to 4B'].push({ priceDiff, price });
    } else if (gexValue >= 4e9 && gexValue < 5e9) {
      intervals['4B to 5B'].push({ priceDiff, price });
    } else if (gexValue >= 5e9 && gexValue < 6e9) {
      intervals['5B to 6B'].push({ priceDiff, price });
    } else if (gexValue >= 6e9 && gexValue < 7e9) {
      intervals['6B to 7B'].push({ priceDiff, price });
    } else if (gexValue >= 7e9) {
      intervals['7B+'].push({ priceDiff, price });
    }
  });

  // Calculate statistics for each interval, adjusting based on skewness and kurtosis
  const gexStatistics = {};

  // Inside calculateGexStatisticsForDays function, modify the interval processing:
  Object.keys(intervals).forEach((interval) => {
      const values = intervals[interval].map(item => item.priceDiff);
      const prices = intervals[interval].map(item => item.price);  // Get the current prices

      if (values.length > 0) {
          const mean = calculateMean(values);
          const stdDev = calculateStandardDeviation(values, mean);
          const skewness = calculateSkewness(values, mean, stdDev);
          const kurtosis = calculateKurtosis(values, mean, stdDev);

          const { upperBound, lowerBound } = adjustBoundsForSkewnessKurtosis(mean, stdDev, skewness, kurtosis);

          // Use the most recent price for the calculation
          const currentPrice = prices[prices.length - 1];  // Get the most recent price

          const upperBoundPrice = currentPrice * (1 + upperBound / 100);
          const lowerBoundPrice = currentPrice * (1 + lowerBound / 100);

          gexStatistics[interval] = {
              mean,
              stdDev1: stdDev,
              stdDev2: stdDev * 2,
              skewness,
              kurtosis,
              adjustedUpperBound: upperBound,
              adjustedLowerBound: lowerBound,
              upperBoundPrice,
              lowerBoundPrice,
              currentPrice  // Add this for reference
          };
      }
  });

  return gexStatistics;
};



// Function to calculate GEX value and price changes for each day (1 to 5)
const calculateGexAndPriceChange = (spxGexData, vvixData) => {
  // Filter out rows that don't have both GEX and VVIX data for the same date
  const pairedData = spxGexData.map((row) => {
    const matchingVvixData = vvixData.find(vvix => vvix.date === row.date);

    if (!matchingVvixData) {
      return null;  // Skip rows where there's no matching VVIX data
    }

    const gexValue = parseFloat(row.gex);
    const priceToday = parseFloat(row.price);
    const vvix_avg_z_score = parseFloat(matchingVvixData.vvix_avg_z_score);
    const vvix_super_smooth_z_score = parseFloat(matchingVvixData.vvix_super_smooth_z_score);

    const getPrevDayDiff = (daysBack) => {
      if (daysBack >= 0 && spxGexData[daysBack]) {
        const pricePrevDay = parseFloat(spxGexData[daysBack].price);
        return ((pricePrevDay - priceToday) / pricePrevDay) * 100;
      }
      return null;
    };

    const day1Diff = getPrevDayDiff(spxGexData.indexOf(row) - 1);
    const day2Diff = getPrevDayDiff(spxGexData.indexOf(row) - 2);
    const day3Diff = getPrevDayDiff(spxGexData.indexOf(row) - 3);
    const day4Diff = getPrevDayDiff(spxGexData.indexOf(row) - 4);
    const day5Diff = getPrevDayDiff(spxGexData.indexOf(row) - 5);

    return {
      date: row.date,
      gexValue,
      price: priceToday,  // Added the price to the returned object
      day1Diff: day1Diff !== null ? day1Diff : 0,
      day2Diff: day2Diff !== null ? day2Diff : 0,
      day3Diff: day3Diff !== null ? day3Diff : 0,
      day4Diff: day4Diff !== null ? day4Diff : 0,
      day5Diff: day5Diff !== null ? day5Diff : 0,
      vvix_avg_z_score,
      vvix_super_smooth_z_score
    };
  });

  return pairedData.filter(item => item !== null);
};

// Function to calculate the GEX statistics for both VVIX conditions
const runGammaExpectedMoveCalculation = () => {
  const store = useStore.getState();
  const spxGexData = store.spxGexData;
  const vvixData = store.vvixData;

  if (!spxGexData || spxGexData.length === 0 || !vvixData || vvixData.length === 0) {
    console.log('No SPX GEX or VVIX data available in the store.');
    return;
  }

  // Pair the SPX GEX data with VVIX data based on the date
  const pairedData = calculateGexAndPriceChange(spxGexData, vvixData);

  // Separate VVIX Above and VVIX Below scenarios with number conversion
  const vvixBelowFilter = pairedData.filter(dataPoint =>
    parseFloat(dataPoint.vvix_avg_z_score) < parseFloat(dataPoint.vvix_super_smooth_z_score)
  );

  const vvixAboveFilter = pairedData.filter(dataPoint =>
    parseFloat(dataPoint.vvix_avg_z_score) >= parseFloat(dataPoint.vvix_super_smooth_z_score)
  );


  // Calculate statistics for VVIX Below
  const gexStatisticsDay1_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 1);
  const gexStatisticsDay2_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 2);
  const gexStatisticsDay3_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 3);
  const gexStatisticsDay4_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 4);
  const gexStatisticsDay5_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 5);
  console.log(gexStatisticsDay1_vvixBelow)

  // Calculate statistics for VVIX Above
  const gexStatisticsDay1_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 1);
  const gexStatisticsDay2_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 2);
  const gexStatisticsDay3_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 3);
  const gexStatisticsDay4_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 4);
  const gexStatisticsDay5_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 5);
  console.log(gexStatisticsDay1_vvixAbove)

  // Log the paired data for debugging
  console.log('Paired Data:', pairedData);

  // Update the Zustand store with all the statistics
  useStore.setState({
    pairedData,
    expectedMoveData: pairedData,
    gexStatisticsDay1_vvixBelow,
    gexStatisticsDay2_vvixBelow,
    gexStatisticsDay3_vvixBelow,
    gexStatisticsDay4_vvixBelow,
    gexStatisticsDay5_vvixBelow,
    gexStatisticsDay1_vvixAbove,
    gexStatisticsDay2_vvixAbove,
    gexStatisticsDay3_vvixAbove,
    gexStatisticsDay4_vvixAbove,
    gexStatisticsDay5_vvixAbove,
  });
};


module.exports = {
  runGammaExpectedMoveCalculation,
};









  // console.log('VVIX Below - GEX Statistics for Day 1:', gexStatisticsDay1_vvixBelow);
  // console.log('VVIX Below - GEX Statistics for Day 2:', gexStatisticsDay2_vvixBelow);
  // console.log('VVIX Below - GEX Statistics for Day 3:', gexStatisticsDay3_vvixBelow);
  // console.log('VVIX Below - GEX Statistics for Day 4:', gexStatisticsDay4_vvixBelow);
  // console.log('VVIX Below - GEX Statistics for Day 5:', gexStatisticsDay5_vvixBelow);
  //
  // console.log('VVIX Above - GEX Statistics for Day 1:', gexStatisticsDay1_vvixAbove);
  // console.log('VVIX Above - GEX Statistics for Day 2:', gexStatisticsDay2_vvixAbove);
  // console.log('VVIX Above - GEX Statistics for Day 3:', gexStatisticsDay3_vvixAbove);
  // console.log('VVIX Above - GEX Statistics for Day 4:', gexStatisticsDay4_vvixAbove);
  // console.log('VVIX Above - GEX Statistics for Day 5:', gexStatisticsDay5_vvixAbove);

















