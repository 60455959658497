import React, { useEffect, useRef } from 'react';
import CustomDataFeed from './CustomDataFeed';
import useStore from '../Store/gamma_expected_move_data_store';
import {gexExpectedMoveIndicator } from './GEM 0';
import {gexExpectedMoveIndicatorDay1 } from './GEM 1';
import {gexExpectedMoveIndicatorDay2 } from './GEM 2';
import {gexExpectedMoveIndicatorDay3 } from './GEM 3';
import {gexExpectedMoveIndicatorDay4 } from './GEM 4';
import { gexIndicator as gexIndicatorGetter } from './Raw GEX';


const AdvancedGEMDashboard = () => {
  const spxData = useStore((state) => state.spxData);
  const spxGexData = useStore((state) => state.spxGexData);
  const vvixData = useStore((state) => state.vvixData);
  const chartWidget = useRef(null);

  // Determine if the device is mobile
  const isMobile = window.innerWidth <= 768;



  useEffect(() => {
    if (
      spxData.length > 0 &&
      spxGexData.length > 0 &&
      vvixData.length > 0
    ) {
      loadChartWithIndicators(spxGexData, vvixData);
    }

    return () => {
      if (chartWidget.current) {
        const chart = chartWidget.current.activeChart();
        chart.onVisibleRangeChanged().unsubscribeAll(this);
        chart.crossHairMoved().unsubscribeAll(this);
      }
    };
  }, [spxData, spxGexData, vvixData]);

  const loadChartWithIndicators = (spxGexData, vvixData, srLevelsData) => {
    const dataFeed = new CustomDataFeed();

    const widget = new window.TradingView.widget({
      container: 'chartContainer',
      library_path: '/charting_library/',
      datafeed: dataFeed,
      symbol: 'SPX',
      interval: '1D',
      autosize: true,
      theme: 'Dark',
      overrides: {
          // 'paneProperties.background': '#000000',
          // 'paneProperties.backgroundType': 0,  // 0 for solid, 1 for gradient
          "paneProperties.backgroundGradientStartColor": "#000000",
          "paneProperties.backgroundGradientEndColor": "#131722",
          'paneProperties.vertGridProperties.color': 'rgba(173,173,173,0.06)',
          'paneProperties.horzGridProperties.color': 'rgba(173,173,173,0.06)',
          'scalesProperties.textColor': '#FFFFFF',
          'toolbarProperties.background': '#000000',
          'toolbarProperties.separator': '#444444',
      },
      disabled_features: isMobile
        ? [
            'scroll_past_end',
            'create_volume_indicator_by_default',
            'control_bar',
            'header_widget',
            'left_toolbar',
            'timeframes_toolbar',
            'edit_buttons_in_legend',
            'context_menus',
            'control_bar',
            'border_around_the_chart',
          ]
        : ['scroll_past_end', 'create_volume_indicator_by_default'],

      enabled_features: [
        'use_localstorage_for_settings',
        'save_chart_properties_to_local_storage',
        'canvas_support',  // Add this
        'chart_property_page_style',  // And this
    ],
      custom_indicators_getter: async (PineJS) => {
        const gexIndicator = await gexIndicatorGetter(PineJS);
        const gexIndicators0 = await gexExpectedMoveIndicator(PineJS);
        const gexIndicators1 = await gexExpectedMoveIndicatorDay1(PineJS);
        const gexIndicators2 = await gexExpectedMoveIndicatorDay2(PineJS);
        const gexIndicators3 = await gexExpectedMoveIndicatorDay3(PineJS);
        const gexIndicators4 = await gexExpectedMoveIndicatorDay4(PineJS);
        return [ ...gexIndicator,
                ...gexIndicators0,
                ...gexIndicators1,
                ...gexIndicators2,
                ...gexIndicators3,
                ...gexIndicators4,
        ];
      },
    });

    widget.onChartReady(() => {
      const chart = widget.activeChart();
          // Add the CSS custom properties here
    widget.setCSSCustomProperty('--tv-color-pane-background', '#000000');
    widget.setCSSCustomProperty('--tv-color-toolbar-button-text', '#FFFFFF');

      chart.createStudy('Raw GEX', false, false, {
        source: 'spxGexData',
      });
      chart.createStudy('GEM 0 DTE', true, false, {
        source: 'spxGexData',
      });
      chart.createStudy('GEM 1 DTE', true, false, {
        source: 'spxGexData',
      });
      chart.createStudy('GEM 2 DTE', true, false, {
        source: 'spxGexData',
      });
      chart.createStudy('GEM 3 DTE', true, false, {
        source: 'spxGexData',
      });
      chart.createStudy('GEM 4 DTE', true, false, {
        source: 'spxGexData',
      });
    });

    chartWidget.current = widget;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      <div
        id="chartContainer"
        style={{
          flex: 1,
          border: '1px solid #333',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      ></div>
    </div>
  );
};

export default AdvancedGEMDashboard;