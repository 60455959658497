import useStore from '../Store/gamma_expected_move_data_store';

export const gexExpectedMoveIndicator = function (PineJS) {
  return Promise.resolve([
    {
      name: 'GEM 0 DTE',
      metainfo: {
        _metainfoVersion: 53,
        id: "GEM_0_DTE@tv-basicstudies-1",
        scriptIdPart: "",
        name: "GEM 0 DTE",
        description: "GEM 0 DTE",
        shortDescription: "GEM 0 DTE",
        isCustomIndicator: true,
        is_hidden_study: false,
        is_price_study: true,
        isLinkedToSeries: true,
        format: {
          type: "price",
          precision: 2
        },
        plots: [
          {
            id: "upper_bound",
            type: "line"
          },
          {
            id: "lower_bound",
            type: "line"
          }
        ],
        styles: {
          upper_bound: {
            title: "Upper Bound",
            histogramBase: 0,
            joinPoints: true
          },
          lower_bound: {
            title: "Lower Bound",
            histogramBase: 0,
            joinPoints: true
          }
        },
        defaults: {
          styles: {
            upper_bound: {
              linestyle: 1,
              linewidth: 1,
              plottype: 0,
              trackPrice: false,
              transparency: 20,
              visible: true,
              color: "#ff0061"
            },
            lower_bound: {
              linestyle: 1,
              linewidth: 1,
              plottype: 0,
              trackPrice: false,
              transparency: 20,
              visible: true,
              color: "#ff0061"
            }
          }
        },
        inputs: [],
        graphics: {},
      },

      constructor: function () {
        this.init = function (ctx) {
          this._context = ctx;
          const store = useStore.getState();
          const spxGexData = store.spxGexData || [];
          const vvixData = store.vvixData || [];

          if (!spxGexData || spxGexData.length === 0) {
            this._boundData = [];
            return;
          }

          // console.log('Initial data:', {
          //   spxGexFirst3: spxGexData.slice(0, 3),
          //   vvixFirst3: vvixData.slice(0, 3)
          // });

          const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

          // Create a daily environment map
          const dailyEnvironment = spxGexData.reduce((acc, entry) => {
            const matchingVvix = vvixData.find(v => v.date === entry.date);
            if (!matchingVvix) return acc;

            const gexValue = parseFloat(entry.gex);
            const price = parseFloat(entry.price);
            const vvix_avg_z_score = parseFloat(matchingVvix.vvix_avg_z_score);
            const vvix_super_smooth_z_score = parseFloat(matchingVvix.vvix_super_smooth_z_score);

            acc[entry.date] = {
              price,
              gexRange: determineGexRange(gexValue),
              vvixCondition: vvix_avg_z_score >= vvix_super_smooth_z_score ? 'above' : 'below',
              timestamp: new Date(entry.date).getTime()
            };

            return acc;
          }, {});

          // console.log('Daily environment first 3:',
          //   Object.entries(dailyEnvironment).slice(0, 3));

          // Process each day and calculate next day's bounds
          this._boundData = Object.entries(dailyEnvironment).map(([date, env]) => {
            // Get the appropriate statistics based on current environment
            const stats = env.vvixCondition === 'above'
              ? store.gexStatisticsDay1_vvixAbove[env.gexRange]
              : store.gexStatisticsDay1_vvixBelow[env.gexRange];

            // Calculate the actual price bounds for next day
            const upperBoundPrice = env.price * (1 + (stats?.adjustedUpperBound || 0) / 100);
            const lowerBoundPrice = env.price * (1 + (stats?.adjustedLowerBound || 0) / 100);

            const dataPoint = {
              time: Math.floor(env.timestamp / 1000),
              upperBound: upperBoundPrice,
              lowerBound: lowerBoundPrice,
              basePrice: env.price,
              environment: {
                gexRange: env.gexRange,
                vvixCondition: env.vvixCondition
              }
            };

            // console.log('Calculated bound data:', {
            //   date,
            //   time: new Date(dataPoint.time * 1000).toISOString(),
            //   bounds: {
            //     upper: upperBoundPrice,
            //     lower: lowerBoundPrice
            //   }
            // });

            return dataPoint;
          });
        };

        this.main = function (ctx) {
            const currentTime = Math.floor(ctx.symbol.time / 1000);

            if (!this._boundData || this._boundData.length === 0) {
                return [NaN, NaN];
            }

            // Find the most recent data point BEFORE the current time
            let closestMatch = null;
            let smallestDiff = Infinity;

            for (const data of this._boundData) {
                if (data.time <= currentTime) {  // Only look at data points before or at current time
                    const timeDiff = currentTime - data.time;
                    if (timeDiff < smallestDiff) {
                        closestMatch = data;
                        smallestDiff = timeDiff;
                    }
                }
            }

            if (closestMatch) {
                // console.log('Matching data point:', {
                //     currentTime: new Date(currentTime * 1000).toISOString(),
                //     matchTime: new Date(closestMatch.time * 1000).toISOString(),
                //     timeDiff: smallestDiff/86400,
                //     bounds: {
                //         upper: closestMatch.upperBound,
                //         lower: closestMatch.lowerBound
                //     }
                // });

                return [
                    { value: closestMatch.upperBound, offset: 1 },
                    { value: closestMatch.lowerBound, offset: 1 }
                ];
            }

            return [NaN, NaN];
        };
      },
    },
  ]);
};

// Helper function stays the same
function determineGexRange(gexValue) {
  if (gexValue < -2e9) return 'Negative (< -2B)';
  if (gexValue >= -2e9 && gexValue < -1e9) return '-2B to -1B';
  if (gexValue >= -1e9 && gexValue < 0) return '-1B to 0B';
  if (gexValue >= 0 && gexValue < 1e9) return '0B to 1B';
  if (gexValue >= 1e9 && gexValue < 2e9) return '1B to 2B';
  if (gexValue >= 2e9 && gexValue < 3e9) return '2B to 3B';
  if (gexValue >= 3e9 && gexValue < 4e9) return '3B to 4B';
  if (gexValue >= 4e9 && gexValue < 5e9) return '4B to 5B';
  if (gexValue >= 5e9 && gexValue < 6e9) return '5B to 6B';
  if (gexValue >= 6e9 && gexValue < 7e9) return '6B to 7B';
  return '7B+';
}