import React, { useEffect, useState } from 'react';
import MarketGreeksWssClient from './Components/gamma-expected_move_wss_client';
import AdvancedGEMDashboard  from './Components/AdvancedGEMDashboard';  // Import the GEM_Chart component
import './App.css';
import { useMemberstack } from "@memberstack/react";  // Import the useMemberstack hook

const allowedPlanIds = [
  'prc_legacy-membership-bg54063e',
  'prc_free-trial-legacy-membership-vi20zyi',
  'pln_vip-membership-c0ea0lhm',
  'prc_14-day-free-trial-aa17a04dc'
];

function App() {
  const [loadingMember, setLoadingMember] = useState(true);  // Track member loading state
  const [member, setMember] = useState(null);  // Store member information
  const memberstack = useMemberstack();

  // Fetch the current member and check their plan
  useEffect(() => {
    memberstack.getCurrentMember()
      .then(({ data: member }) => {
        if (!member) {
          // If no member is found, redirect to the denied page
          window.location.href = 'https://www.spxgamma.com/denied';
        } else {
          // Check if the member's plan is allowed and active or trialing
          const planConnections = member.planConnections;
          const hasValidPlan = planConnections?.some(plan => {
            const planId = plan.payment?.priceId || plan.planId; // Handle both paid and free plans
            return (
              (plan.status === 'ACTIVE' || plan.status === 'TRIALING') &&
              allowedPlanIds.includes(planId) // Require active or trialing status
            );
          });

          if (hasValidPlan) {
            setMember(member);  // Set the member info
            setLoadingMember(false);  // Stop loading
            console.log("Current member with valid plan (ACTIVE or TRIALING):", member);
          } else {
            // Redirect if the member doesn't have a valid plan
            window.location.href = 'https://www.spxgamma.com/denied';
          }
        }
      })
      .catch(err => {
        console.error("Error fetching current member:", err);
        window.location.href = 'https://www.spxgamma.com/denied';  // Redirect on error
      });
  }, [memberstack]);

  // While member info is loading, show a loading message
  if (loadingMember) {
    return <div>Loading...</div>;  // Display a loading screen while checking membership
  }

  return (
    <div className="App">
      <MarketGreeksWssClient />
      <AdvancedGEMDashboard />
    </div>
  );
}

export default App;





// import React, { useEffect } from 'react';
// import MarketGreeksWssClient from './Components/gamma-expected_move_wss_client';
// import GEM_Chart from './Components/GEM_Chart';  // Import the GEM_Chart component
// import './App.css';
//
// function App() {
//
//   useEffect(() => {
//   }, []);
//   return (
//     <div className="App">
//       <header className="App-header">
//         <div className="app-container">
//           <MarketGreeksWssClient /> {/* This will initiate the WebSocket connection */}
//           <GEM_Chart /> {/* This will render the chart */}
//         </div>
//       </header>
//     </div>
//   );
// }
//
// export default App;
