import useStore from '../Store/gamma_expected_move_data_store';

export const gexExpectedMoveIndicatorDay3 = function (PineJS) {
  return Promise.resolve([
    {
      name: 'GEM 3 DTE',
      metainfo: {
        _metainfoVersion: 53,
        id: "GEM 3 DTE@tv-basicstudies-1",
        scriptIdPart: "",
        name: "GEM 3 DTE",
        description: "GEM 3 DTE",
        shortDescription: "GEM 3 DTE",
        isCustomIndicator: true,
        is_hidden_study: false,
        is_price_study: true,
        isLinkedToSeries: true,
        format: {
          type: "price",
          precision: 2
        },
        plots: [
          {
            id: "upper_bound",
            type: "line"
          },
          {
            id: "lower_bound",
            type: "line"
          }
        ],
        styles: {
          upper_bound: {
            title: "Upper Bound",
            histogramBase: 0,
            joinPoints: true
          },
          lower_bound: {
            title: "Lower Bound",
            histogramBase: 0,
            joinPoints: true
          }
        },
        defaults: {
          styles: {
            upper_bound: {
              linestyle: 1,
              linewidth: 1,
              plottype: 0,
              trackPrice: false,
              transparency: 20,
              visible: true,
              color: "#8ae800"
            },
            lower_bound: {
              linestyle: 1,
              linewidth: 1,
              plottype: 0,
              trackPrice: false,
              transparency: 20,
              visible: true,
              color: "#8AE800"
            }
          }
        },
        inputs: [],
        graphics: {},
      },

      constructor: function () {
        this.init = function (ctx) {
          this._context = ctx;
          const store = useStore.getState();
          const spxGexData = store.spxGexData || [];
          const vvixData = store.vvixData || [];

          if (!spxGexData || spxGexData.length === 0) {
            this._boundData = [];
            return;
          }

          const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

          // Create a daily environment map
          const dailyEnvironment = spxGexData.reduce((acc, entry) => {
            const matchingVvix = vvixData.find(v => v.date === entry.date);
            if (!matchingVvix) return acc;

            const gexValue = parseFloat(entry.gex);
            const price = parseFloat(entry.price);
            const vvix_avg_z_score = parseFloat(matchingVvix.vvix_avg_z_score);
            const vvix_super_smooth_z_score = parseFloat(matchingVvix.vvix_super_smooth_z_score);

            acc[entry.date] = {
              price,
              gexRange: determineGexRange(gexValue),
              vvixCondition: vvix_avg_z_score >= vvix_super_smooth_z_score ? 'above' : 'below',
              timestamp: new Date(entry.date).getTime()
            };

            return acc;
          }, {});

          // Process each day and calculate next day's bounds
          this._boundData = Object.entries(dailyEnvironment).map(([date, env]) => {
            // Get the appropriate statistics based on current environment - using Day 4 stats
            const stats = env.vvixCondition === 'above'
              ? store.gexStatisticsDay4_vvixAbove[env.gexRange]
              : store.gexStatisticsDay4_vvixBelow[env.gexRange];

            // Calculate the actual price bounds for next day
            const upperBoundPrice = env.price * (1 + (stats?.adjustedUpperBound || 0) / 100);
            const lowerBoundPrice = env.price * (1 + (stats?.adjustedLowerBound || 0) / 100);

            return {
              time: Math.floor(env.timestamp / 1000),
              upperBound: upperBoundPrice,
              lowerBound: lowerBoundPrice,
              basePrice: env.price,
              environment: {
                gexRange: env.gexRange,
                vvixCondition: env.vvixCondition
              }
            };
          });
        };

        this.main = function (ctx) {
          const currentTime = Math.floor(ctx.symbol.time / 1000);

          if (!this._boundData || this._boundData.length === 0) {
            return [NaN, NaN];
          }

          let closestMatch = null;
          let smallestDiff = Infinity;

          for (const data of this._boundData) {
            if (data.time <= currentTime) {
              const timeDiff = currentTime - data.time;
              if (timeDiff < smallestDiff) {
                closestMatch = data;
                smallestDiff = timeDiff;
              }
            }
          }

          if (closestMatch) {
            return [
              { value: closestMatch.upperBound, offset: 4 }, // Note: offset is now 4 for Day 4
              { value: closestMatch.lowerBound, offset: 4 }
            ];
          }

          return [NaN, NaN];
        };
      },
    },
  ]);
};

function determineGexRange(gexValue) {
  if (gexValue < -2e9) return 'Negative (< -2B)';
  if (gexValue >= -2e9 && gexValue < -1e9) return '-2B to -1B';
  if (gexValue >= -1e9 && gexValue < 0) return '-1B to 0B';
  if (gexValue >= 0 && gexValue < 1e9) return '0B to 1B';
  if (gexValue >= 1e9 && gexValue < 2e9) return '1B to 2B';
  if (gexValue >= 2e9 && gexValue < 3e9) return '2B to 3B';
  if (gexValue >= 3e9 && gexValue < 4e9) return '3B to 4B';
  if (gexValue >= 4e9 && gexValue < 5e9) return '4B to 5B';
  if (gexValue >= 5e9 && gexValue < 6e9) return '5B to 6B';
  if (gexValue >= 6e9 && gexValue < 7e9) return '6B to 7B';
  return '7B+';
}